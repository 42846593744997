
/* eslint-disable no-use-before-define */
import {
  computed, defineComponent, reactive, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ContainerHeader from '@/components/ContainerHeader.vue';
import PageControls from '@/components/PageControls.vue';
import {
  TaxPayer, TradeObject,
} from '@/types/ObjectOfTrade';
import Container from '@/components/Container.vue';
import UploadNewItems from '@/components/Modals/uploadNewItems.vue';
import api from '@/api';
import ViewportObserver from '@/components/ViewportObserver.vue';
import usePagination from '@/modules/usePagination';
import { badgeProps, parseDate } from '@/utils';
import ColorSelector from '@/components/UI/ColorSelector.vue';
import { ObjectStatus } from '@/constants/objectStatuses';

export default defineComponent({
  name: 'ObjectId',
  components: {
    UploadNewItems,
    Container,
    PageControls,
    ContainerHeader,
    ViewportObserver,
    ColorSelector,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const showAddingNewTaxPayer = ref(false);
    const onlyLiquidated = ref(false);

    interface CurrentFilters extends Record<string, unknown> {
      status: ObjectStatus
    }

    const taxPayers = usePagination({
      filters: {
        nameorinn: '',
        status: {} as ObjectStatus,
      },
      delayedFilters: ['nameorinn'],
      immediateFilters: [],
      rows: [] as TaxPayer[],
      async fetchContents() {
        const { nameorinn } = taxPayers.filters as {nameorinn: string};
        const withDuplicates = store.state.auth.isAdmin;
        const filters = this.filters as CurrentFilters;
        const status = filters.status.value ? `${filters.status.value}` : null;
        const params = {
          objectid: `${route.params.objectId}`,
          ...nameorinn && { nameorinn },
          ...taxPayers.pagination.params,
          duplicate: withDuplicates,
          ...onlyLiquidated.value && { liquidated: true },
          ...status && { status },
        };
        return (await api.taxPayers.findAll(params)).data;
      },
    });
    taxPayers.fetch();

    watch(onlyLiquidated, () => {
      taxPayers.fetch();
    });
    watch(() => taxPayers.filters.status, () => {
      taxPayers.fetch();
    });

    const tradeObject = reactive({
      values: null as TradeObject | null,
      async fetch() {
        // this.values = (await api.objectsOfTrade.getById(route.params.objectId)).data.tradeObject;
        this.values = (await api.objectsOfTrade.getInfoById(route.params.objectId)).data;
      },
    });
    tradeObject.fetch();

    const statusColor = computed<string>(() => {
      switch (tradeObject.values?.objectStatus?.toUpperCase()) {
        case 'LIQUIDATED':
          return '#000';
        case 'NOTPROCESSED':
          return '#ff9d7e';
        case 'DUPLICATE':
          return '#FF0000';
        default:
          return '#7eb9ff';
      }
    });

    const cols = [
      {
        component: (row: TaxPayer) => ({
          name: 'ChangeStatus',
          ...badgeProps(row.objectStatus),
          'onUpdate:status': async (objectStatus: string) => {
            await api.taxPayers.saveById(row.id, { objectStatus });
            taxPayers.pagination.reset();
            await taxPayers.fetch();
          },
        }),
      },
      {
        component: (row: TaxPayer) => ({
          name: 'Liquidated',
          liquidated: row.objectStatusIsLiquidated,
        }),
      },
      {
        label: 'Налогоплательщик',
        // display: (row: TaxPayer) => row.objectName,
        // width: '30%',
        component: (row: TaxPayer) => ({
          name: 'router-link',
          class: 'link',
          value: row.objectName,
          to: { name: 'registry-tax-payers-payer-id', params: { payerId: row.id } },
        }),
      },
      {
        label: 'УГНС',
        display: (row: TaxPayer) => row.tradeObject?.ugnsTno?.ugnsTnoCode,
      },
      {
        label: 'дата изменения статуса',
        display: (row: TaxPayer) => `${parseDate(row.objectStatusDate) || 'не задана'}`,
      },
      {
        label: 'инспектор',
        display: (row: TaxPayer) => getInspectorName(row),
      },
    ];

    const redirectToMap = () => {
      store.commit('map/SET_SEARCH', tradeObject.values?.address?.yandexAddressFull);
      router.push({ name: 'home-map', query: { addressId: tradeObject.values?.id } });
    };

    const getInspectorName = (row: TaxPayer) => {
      if (row.objectStatusInstaller?.lastName || row.objectStatusInstaller?.firstName || row.objectStatusInstaller?.middleName) {
        return `${row.objectStatusInstaller?.lastName || ''} ${row.objectStatusInstaller?.firstName || ''} ${row.objectStatusInstaller?.middleName || ''}`;
      }
      if (row.inspector?.lastName || row.inspector?.firstName || row.inspector?.middleName) {
        return `${row.inspector?.lastName || ''} ${row.inspector?.firstName || ''} ${row.inspector?.middleName || ''}`;
      }
      return 'не назначен';
    };

    return {
      taxPayers,
      tradeObject,
      statusColor,
      cols,
      showAddingNewTaxPayer,
      router,
      parseDate,
      onlyLiquidated,
      redirectToMap,
    };
  },
});
